'use client'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useTranslations } from 'next-intl'

import { Flex } from '@/components/flex'
import { Link } from '@/components/link/link'
import { getCookie, setCookie } from '@/common/utils/cookie-utils'
import { Button } from '../button'

const COOKIE_KEY = 'user_allowed_save_cookie'
const COOKIE_VALUE = '%7B%225%22%3A1%7D'

export const CookieBanner = () => {
  const [isConsentHidden, setIsConsentHidden] = useState(true)

  const t = useTranslations('Cookies')

  const updateCookie = () => {
    setCookie(COOKIE_KEY, COOKIE_VALUE)
    setIsConsentHidden(true)
  }

  useEffect(() => {
    const isCookie = !!getCookie(COOKIE_KEY)
    setIsConsentHidden(isCookie)
  }, [])

  return (
    <Flex
      className={twMerge(
        'fixed bottom-0 inset-x-0',
        'w-full z-50 p-3.5',
        'bg-white box-border',
        'shadow-[0_0_5px_0_rgba(0,0,0,0.5)]',
        'flex items-center flex-wrap xl:flex-nowrap',
        isConsentHidden && 'hidden',
      )}
    >
      <div className="mr-3">
        <strong>{t('banner.content')}</strong>{' '}
        <span>{t('banner.service')} </span>
        <Link
          isExternal
          title="privacy policy"
          href="/privacy-policy-cookie-restriction-mode"
          className="text-secondary hover:text-secondary-hover inline"
        >
          {t('banner.link')}
        </Link>
      </div>
      <Button
        variant="secondary"
        size="sm"
        className="mt-3 mr-0 xl:mr-3 xl:mt-0  px-7 font-bold"
        onClick={updateCookie}
      >
        {t('banner.button')}
      </Button>
    </Flex>
  )
}
