'use client'

import { useMemo } from 'react'

import { DognetTracking } from '@/common/services/tracking/dognet'
import { StoreConfigType } from '@/common/types'

interface WidgetsProps {
  storeConfig: StoreConfigType
}

export const Widgets = ({ storeConfig }: WidgetsProps) => {
  const { dognetS2s } = storeConfig

  const showDognet = useMemo(() => {
    return dognetS2s?.enabled && dognetS2s?.accountId
  }, [dognetS2s])

  return (
    <>
      {showDognet && (
        <DognetTracking cookieConsentCheck={dognetS2s?.cookieConsent} />
      )}
    </>
  )
}
