'use client'

import { useEffect } from 'react'

import {
  addVisitorId,
  fetchVisitorId,
  VISITOR_ID_COOKIE_KEY,
} from '@/common/services/tracking/dognet/api'

const GTM_COOKIE_KEY = 'CookieConsent'

function isCookieConsentStatisticsPermitted(): boolean {
  const allCookies = document.cookie.split('; ')
  const isContentCookie = allCookies.find((row) =>
    row.startsWith(GTM_COOKIE_KEY),
  )

  return !!isContentCookie?.match('statistics:true')
}

type DognetTrackingProps = {
  cookieConsentCheck?: boolean
}
export const DognetTracking = ({ cookieConsentCheck }: DognetTrackingProps) => {
  useEffect(() => {
    if (!isCookieConsentStatisticsPermitted() && cookieConsentCheck) {
      return
    }

    const searchParams = new URLSearchParams(window.location.search)
    const oldVisitorId = document.cookie
      .split('; ')
      .find((item) => item.startsWith(VISITOR_ID_COOKIE_KEY))
      ?.split('=')?.[1]

    if (searchParams.has('visitorId')) {
      const visitorId = searchParams.get('visitorId')
      addVisitorId(visitorId, oldVisitorId)
    } else if (searchParams.has('a_aid') && searchParams.has('a_bid')) {
      fetchVisitorId(oldVisitorId)
    }
  }, [cookieConsentCheck])

  return null
}
