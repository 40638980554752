import { consoleError } from '@/common/utils/console'

export const VISITOR_ID_COOKIE_KEY = '_dgtvid'

function storeCookie(value: string): void {
  const expires = new Date()
  expires.setDate(expires.getDate() + 30)
  document.cookie = `${VISITOR_ID_COOKIE_KEY}=${value}; expires=${expires.toUTCString()}`
}

export async function fetchVisitorId(oldVisitorId?: string): Promise<void> {
  try {
    const params = {
      url: window.location.origin + window.location.pathname,
      referrer: '',
      getParams: window.location.search,
    }
    const response = await fetch(
      '/api/rest/V1/dognet/visitorId?' + new URLSearchParams(params).toString(),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`)
    }

    const visitorId = await response.json()

    if (oldVisitorId !== visitorId) {
      storeCookie(visitorId)
    }
  } catch (error: any) {
    consoleError('services/tracking/dognet/api.ts [fetchVisitorId]', error)
    throw new Error(error)
  }
}

export async function addVisitorId(
  visitorId: string | null,
  oldVisitorId?: string,
): Promise<void> {
  if (visitorId && visitorId !== oldVisitorId) {
    try {
      const response = await fetch('/api/rest/V1/dognet/visitorId', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          visitorId,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`)
      }

      storeCookie(visitorId)
    } catch (error: any) {
      consoleError('services/tracking/dognet/api.ts [addVisitorId]', error)

      throw new Error(error)
    }
  }
}
