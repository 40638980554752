import React, { forwardRef, HTMLAttributes } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

const variants = cva('flex', {
  variants: {
    variant: {
      bordered: ['border', 'border-black', 'border-2'],
    },
    center: {
      true: ['items-center', 'justify-center'],
    },
    full: {
      true: ['w-full', 'h-full'],
    },
  },
  defaultVariants: {
    variant: undefined,
  },
})

interface FlexProps extends HTMLAttributes<HTMLDivElement> {
  full?: boolean
  center?: boolean
  variant?: 'bordered'
}

export const Flex = forwardRef<HTMLDivElement, FlexProps>(
  ({ variant, center, className, ...rest }, ref) => (
    <div
      className={twMerge(
        variants({
          center,
          variant,
          className,
        }),
      )}
      {...rest}
      ref={ref}
    />
  ),
)
Flex.displayName = 'Flex'
