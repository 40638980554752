'use client'

import { useEffect } from 'react'

export function GtmTrackingIndicator(): null {
  useEffect(() => {
    window.WAS_isSPA = 'yes'
  }, [])

  return null
}
